<template>
  <div class="container">
    <div class="content-box">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            background-color="#d7e7f3"
            text-color="#343434"
            active-text-color="#0359AC"
            router
          >
            <el-menu-item index="/myquality/huizong">
              <i class="iconfont icon-xueshengzongsuhuizong"></i>
              <span slot="title"> 综素成绩汇总</span>
            </el-menu-item>
            <el-menu-item index="/myquality/jindu">
              <i class="iconfont icon-jindu"></i>
              <span slot="title"> 综素完成进度</span>
            </el-menu-item>
            <el-menu-item index="/myquality/pinde">
              <i class="iconfont icon-3_11sixiangpinde"></i>
              <span slot="title"> 思想品德</span>
            </el-menu-item>
            <el-menu-item index="/myquality/xueye">
              <i class="iconfont icon-xueyeshuiping"></i>
              <span slot="title"> 学业水平</span>
            </el-menu-item>
            <el-menu-item index="/myquality/shenxin">
              <i class="iconfont icon-shengxinjiankang"></i>
              <span slot="title"> 身心健康</span>
            </el-menu-item>
            <el-menu-item index="/myquality/yishu">
              <i class="iconfont icon-yishuxiuyang"></i>
              <span slot="title"> 艺术素养</span>
            </el-menu-item>
            <el-menu-item index="/myquality/shehui">
              <i class="iconfont icon-shehuishijian"></i>
              <span slot="title"> 社会实践</span>
            </el-menu-item>
			<el-menu-item index="/myquality/midExamPhy">
			  <i class="iconfont icon-airudiantubiaohuizhi-zhuanqu_weiyundong"></i>
			  <span slot="title">中考体育</span>
			</el-menu-item>
            <el-menu-item index="/myquality/tixi">
              <i class="iconfont icon-tixi"></i>
              <span slot="title"> 评价指标体系</span>
            </el-menu-item>
            <el-menu-item index="/myquality/xinli">
              <i class="iconfont icon-gongxiang"></i>
              <span slot="title"> 心理知识共享</span>
            </el-menu-item>
            <el-menu-item index="/myquality/tuijian">
              <i class="iconfont icon-tuijian"></i>
              <span slot="title"> 中学生课外读物推荐</span>
            </el-menu-item>
            <el-menu-item index="/myquality/yingshi">
              <i class="el-icon-video-camera"></i>
              <span slot="title">中学生影视推荐</span>
            </el-menu-item>
            <el-menu-item index="/myquality/dangan">
              <i class="iconfont icon-dangan"></i>
              <span slot="title"> 我的综素档案</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="19">
          <router-view />
        </el-col>
      </el-row>
    </div>
    <!-- <div class="no-more">没有更多了</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .content-box {
    background: rgba(206, 223, 236, 0.45);
    padding: 18px 18px 18px 0;
    border-radius: 0 0 6px 6px;
    .el-menu {
      border: none;
      .el-menu-item {
        font-weight: bold;
        border-left: 4px solid transparent;
        i{
          font-size: 22px;
          font-weight: normal;
          color: #343434;
        }
        &.is-active {
          border-left: 4px solid #198af7 !important;
          background-color: #c3dcef !important;
          i{color: #0359AC;}
        }
      }
    }
  }
  .no-more{
    height: 50px;
    text-align: center;
    color: rgba(25, 138, 247);
    line-height: 50px;
    background: #CEDFEC;
    opacity: 0.46;
    border-radius: 6px;
    margin-top: 14px;
  }
}
</style>